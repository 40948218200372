"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const browser_rum_1 = require("@datadog/browser-rum");
window.initializeDataDog = (options) => {
    console.log('Initializing datadog');
    browser_rum_1.datadogRum.init({
        applicationId: 'e49e0741-5da3-48f4-a75e-1baacc599bbd',
        clientToken: options.token,
        site: 'datadoghq.com',
        service: 'legionelladossier',
        env: options.environment,
        // Specify a version number to identify the deployed version of your application in Datadog
        version: options.version,
        sessionSampleRate: 5,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        // subdomains are included automatically
        allowedTracingUrls: ['https://qa.legionelladossier.nl', 'https://app.legionelladossier.com']
    });
    browser_rum_1.datadogRum.startSessionReplayRecording();
};
